@import '../../../assets/scss/theme-color.scss';

.main-header {
	background-color: $white;
	padding: 18px 20px;
	border-bottom: 2px solid $antiflashwhite;
	position: sticky;
	top: 0;
	z-index: 9;

	.user-profile {
		display: flex;
		align-items: center;

		.btn {
			border: 0px;
		}

		.user-names {
			margin: 10px 0px;
		}

		.dropdown-menu {
			padding: 0px;
			border-radius: $border-radius;
			max-width: 200px;
			width: 100%;
			box-shadow: rgba(0, 0, 0, 0.1607843137) 0px 1px 4px;
			border: 0;


			li {

				.dropdown-item {
					font-size: 14px;
					padding: 10px 15px;

					&:hover {
						color: $white;
						background-color: $primary;
					}

					&:focus {
						color: $white;
						background-color: $primary;
					}


				}

				&:first-child {
					a {
						border-top-right-radius: $border-radius;
						border-top-left-radius: $border-radius;
					}
				}

				&:last-child {
					button {
						border-bottom-right-radius: $border-radius;
						border-bottom-left-radius: $border-radius;
					}
				}
			}
		}

		.show {

			&::after {
				transform: rotate(180deg);
			}
		}

		.avtar-user {
			background: $primary;
			// padding: 7px;
			border-radius: 50%;
			margin-right: 5px;
			font-size: 11px;
			font-weight: 600;
			color: $white;
			height: 30px;
			width: 30px;
			align-items: center;
			display: flex;
			justify-content: center;

			img {
				border-radius: 17px;
				object-fit: cover;
				height: 35px;
				width: 35px;
			}
		}
	}
}
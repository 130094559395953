@import '../../../assets/scss/theme-color.scss';


.main-content {
	max-width: calc(100% - 250px);
	width: 100%;
	float: right;
	transition: all .8s ease;
	padding: 0px;

	.main-wrapper {
		margin: 0px 20px;
		padding: 20px 0px 10px;
		height: calc(100vh - 50px);

		.card {
			background-color: $white;
			box-shadow: #00000029 0px 1px 4px;
			border-radius: 5px;
			border: 0px;

			.card-header {
				padding: 15px 18px;
				position: relative;
			}
		}
	}
}

.dashboard-section {

	.chart-card {
		.card {
			background-color: $white;
			box-shadow: rgba(0, 0, 0, 0.1607843137) 0px 1px 4px;
			border-radius: 5px;
			border: 0px;
			padding: 20px;
			height: 200px;
			justify-content: center;
			align-items: center;

			@media screen and (max-width: 1366px) {
				padding: 12px;
			}

			@media screen and (max-width: 991px) {
				margin-bottom: 20px;
			}

			.appointment-sub-heading {
				font-weight: 600;
				color: #54a5d5;
				margin-bottom: 5px;
			}

			h4 {
				font-weight: 600;
			}

			ul {
				margin-bottom: 0px;

				li {
					text-align: center;
					font-size: 13px;

					@media screen and (max-width: 1366px) {
						font-size: 12px;
					}

					span {
						display: block;
					}
				}
			}
		}

	}

	.appointment-table {
		overflow: auto;
		height: calc(100vh - 322px);

		.card {


			.card-header {
				position: sticky !important;
				top: 0 !important;

				@media screen and (max-width: 1400px) {
					z-index: 999;
				}

				h5 {
					margin-bottom: 0px;
				}

				a {
					color: $primary;
					text-decoration: underline;
				}
			}

			.card-body {
				padding: 0px;

				@media screen and (max-width: 1440px) {
					overflow: auto;
				}

				table {
					thead {
						position: sticky;
						top: 57px;
						background: #fff;

						@media screen and (max-width: 1440px) {
							position: inherit;
						}
					}
				}
			}
		}
	}
}
@import '../../../assets/scss/theme-color.scss';


.auth-form {
  align-items: center;
  justify-content: center;
  height: 100vh;

  @media only screen and (max-width: 991px) {
    height: auto;
  }

  .login-form-col {
    padding-left: 30px;
    padding-right: 20px;

    @media only screen and (max-width: 991px) {
      padding: 0px;
    }
  }

  .card {
    border: 0px;
    box-shadow: 3.67px 4.89px 52.91px 0px #0000001F;
    border-radius: 30px;

    @media only screen and (max-width: 991px) {
      padding: 30px;
      border-radius: 20px;
    }

    .brand-logo {
      margin-bottom: 20px;

      h4 {
        margin-bottom: 8px;
      }

      img {
        width: 160px;
        height: 41px;
        max-width: 100%;
        max-height: 100%;
        margin-bottom: 40px;

        @media only screen and (max-width: 991px) {
          margin-bottom: 20px;

        }
      }
    }

    .form-group {
      position: relative;

      .show-password {
        right: 0;
        position: absolute;
        top: 28px;
      }
    }

    .btn-primary {
      width: 100%;
      // margin-top: 30px;
    }

    .btn-link {
      color: $primary;
      margin-top: auto;
      margin-bottom: auto;
      padding: 0px;
      text-decoration: none;

      @media only screen and (max-width: 550px) {
        display: flex;
        justify-content: center;
      }
    }

    .forgot-password-link {
      display: flex;
      align-items: center;
      justify-content: space-between;

      @media only screen and (max-width: 550px) {
        display: block;
      }

      .form-group {
        margin-bottom: 0px;
      }
    }

    .hand-moblie-col {
      text-align: end;

      @media only screen and (max-width: 1440px) {
        text-align: end;
      }

      @media only screen and (max-width: 991px) {
        margin-top: 20px;
      }

      img {
        width: 100%;
        min-height: 650px;
        object-fit: cover;
        border-radius: 0px 30px 30px 0px;

        @media only screen and (max-width: 991px) {
          border-radius: 20px;
        }

        @media only screen and (max-width: 767px) {
          min-height: auto;
        }
      }
    }
  }

  p {
    margin-bottom: 0px;
  }

  .login-link-button {
    text-align: center;
    margin-top: 15px;

    .btn-link {
      padding: 0px;
      text-decoration: none;
    }
  }

  .login-password-field {
    position: relative;

    .btn-link {
      position: absolute;
      top: -4px;
      right: 0px;
    }
  }

  .verification-form {

    .security-code {
      .form-control {
        height: 40px !important;
        width: 42px !important;

      }
    }

    .get-the-code {
      align-items: center;
      display: flex;
    }

    .spinner-border {
      --bs-spinner-width: 18px;
      --bs-spinner-height: 18px;
      --bs-spinner-border-width: 0.15em;
    }
  }

  .already-account {
    text-align: center;
    margin-top: 40px;

    p {
      margin-bottom: 0px;

      .btn-link {
        padding: 0px;
        padding-left: 5px;
        color: $primary;
      }
    }
  }
}
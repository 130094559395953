@import '../../../assets/scss/theme-color.scss';

.sidebar-colums {
	max-width: 250px;
	width: 100%;
	height: 100vh;
	background-color: $white;
	border-right: 2px solid $antiflashwhite;
	padding-top: 15px;
	float: left;
	position: fixed;
	left: 0;
	display: flex;
	flex-direction: column;

	.sidebar-inner {
		overflow-y: auto;
		margin-bottom: auto;
	}

	.main-logo {
		margin-bottom: 30px;
		padding-left: 12px;
	}

	ul {
		margin-bottom: 15px;

		h6 {
			font-size: 12px;
			font-weight: 400;
			padding-left: 14px;
			margin-bottom: 0;
			color: $taupegray;
		}

		li {
			margin-bottom: 10px;

			a {
				font-weight: 500;
				font-size: 14px;
				display: block;
				padding: 12px;
				color: $black;

				span {
					margin-right: 10px;
				}
			}

			.active {
				background-color: #54a5d529;
				border-right: 2px solid $primary;
				color: $primary;
			}

			[aria-expanded="true"] {
				&::after {
					transform: rotate(181deg);
					transition: all 0.5s;

				}
			}

			.btn-toggle {
				border: 0px;
				font-weight: 500;
				font-size: 14px;
				display: block;
				padding: 12px;
				color: $black;
				width: 100%;
				text-align: start;
				position: relative;

				&::after {
					content: "";
					background-image: url(./assets/icons/arrow-down.svg);
					height: 20px;
					width: 20px;
					position: absolute;
					right: 11px;
					bottom: 0;
					top: 0;
					margin: auto;
					background-size: contain;
					transition: all 0.5s;

				}

				&.show {}

				span {
					margin-right: 10px;
				}
			}
		}
	}

	.logout-col {
		.btn {
			font-weight: 500;
			padding: 20px 12px;
			font-size: 14px;
			display: block;
			width: 100%;
			text-align: start;
			border: 0;
			border-top: 2px solid $gainsboro;
			border-radius: 0;

			&:active {
				border-color: $gainsboro;
			}

			span {
				margin-right: 10px;
			}
		}
	}
}
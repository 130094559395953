@import "../../../../assets/scss/theme-color.scss";

.customer-section {
	.pb-10 {
		h5 {
			margin-bottom: 0px;
		}
	}

	.card {
		padding: 0px;

		.card-header {
			padding: 8px 18px;

			h6 {
				margin-bottom: 0px;
			}

			ul {
				margin-bottom: 0px;

				li {
					.booking-tab {
						border: 0px;
						padding: 0px;
					}
				}
			}
		}

		.card-body {
			padding: 0px;
		}

		.customer-name {
			width: 160px;
		}

		// .description-content {
		// 	width: 200px;
		// }
	}

	table {
		tbody {
			tr {
				td {
					.chat-icon {
						@media only screen and (max-width: 1180px) {
							margin-right: 2px !important;
						}

						// @media only screen and (max-width: 1275px) {
						// 	margin-right: 0px !important;
						// }
					}

					ul {
						li {
							@media only screen and (max-width: 1290px) {
								margin-bottom: 5px;
								// margin-right: 0px;
							}
						}
					}
				}
			}
		}
	}
}
@import "../../../../assets/scss/theme-color.scss";

.change-passwrod-section {

	.avatar-upload {

		.avatar-preview {
			width: 100%;
		}
	}

	.profile-form {
		.avatar-upload {
			@media only screen and (max-width: 991px) {
				margin-bottom: 15px;
			}
		}
	}
}
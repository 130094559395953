@import "../../../../assets/scss/theme-color.scss";

.booking-section {
	.pb-10 {
		h5 {
			margin-bottom: 0px;
		}
	}

	.card {
		padding: 0px;

		.card-header {
			padding: 8px 18px;

			h6 {
				margin-bottom: 0px;
			}

			ul {
				margin-bottom: 0px;

				li {
					.booking-tab {
						border: 0px;
						padding: 0px;
					}
				}
			}

			.btn-primary {
				padding: 5px 10px;
			}
		}
	}

	thead {
		tr {
			.dropdown-bookign {
				width: 95px !important;
			}
		}
	}

	table {
		tbody {
			tr {
				td {

					ul {
						li {
							@media only screen and (max-width: 1440px) {
								margin-bottom: 5px;
								margin-right: 6px;

							}
						}
					}
				}


			}
		}
	}

}
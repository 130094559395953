@import "bootstrap/scss/bootstrap.scss";
@import "../scss/button-loading.scss";
@import "../scss/form-control.scss";
@import "./theme-color.scss";
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap");
// @import '../../views/components/form-inputs/Button/style.scss';

html,
body {
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
  font-size: 15px;
  font-weight: 400;
  background-color: #f9fafb;
}

// .container {
//   max-width: 90%;
//   width: 1345px;
//   margin: auto;

//   @media only screen and (max-width: 1440px) {
//     max-width: 95%;
//   }

//   @media only screen and (max-width: 767px) {
//     max-width: 97%;
//   }
// }

a {
  text-decoration: none;
  color: $dark;
}

a:hover {
  color: $dark;
}

h1 {
  font-size: 50px;
  line-height: 80px;
}

h2 {
  font-size: 40px;
  line-height: 30px;
}

h3 {
  font-size: 30px;
  line-height: 34px;
}

h4 {
  font-size: 24px;
  line-height: 130%;
}

h5 {
  font-size: 20px;
  line-height: 130%;
}

h6 {
  font-size: 18px;
  line-height: 20px;
  font-weight: 500;
}

.pb-10 {
  padding-bottom: 10px;
}

.space-between {
  justify-content: space-between;
  display: flex;
}

.justify-center {
  justify-content: center;
  display: flex;
}

.align-middles {
  align-items: center;
  display: flex;
}

table {
  &.table {
    margin-bottom: 0;

    tr {
      vertical-align: middle;
      height: 55px;

      th,
      td {
        &:first-child {
          padding-left: 18px;
        }

        &:last-child {
          padding-right: 18px;
          text-align: end;
        }
      }
    }

    thead {
      tr {
        th {
          font-weight: 600;
          background-color: #54a5d529;

          .btn {
            font-weight: 600;

            .svg-inline--fa {
              margin-left: 6px !important;
            }
          }

          &.status-field {
            .btn {
              border: 0px;
              padding: 0px;
            }

            .dropdown-menu {
              padding: 0px;
              border-radius: 0px;
              padding: 0px;
              border-radius: $border-radius;
              max-width: 200px;
              width: 100%;
              box-shadow: rgba(0, 0, 0, 0.1607843137) 0px 1px 4px;
              border: 0;

              .dropdown-item {
                font-size: 14px;

                &:hover {
                  background-color: $success;
                  color: $white;
                }
              }
            }
          }

          &.action-button {
            width: 180px;
          }

          .fa-arrow-up,
          .fa-arrow-down {
            color: #198754;
          }



          .arrow-up-down {
            transform: rotate(90deg);
          }
        }

        .dropdown {
          .btn {
            font-weight: 600;
            border: 0px;
          }

          .dropdown-menu {
            // padding: 0px;

            .dropdown-item {
              font-weight: 400;
              color: $dark;
              text-decoration: none;
              font-size: 15px;
              margin-bottom: 5px;

              &:last-child {
                margin-bottom: 0px;
              }

              &:hover {
                background-color: $primary;
                color: $white;
              }
            }

            .active {
              background-color: $primary !important;
              color: $white;
            }
          }
        }
      }
    }

    tbody {
      tr {
        td {
          ul {
            &.list-inline {
              margin-bottom: 0;
            }

            li {
              &:not(:last-child) {
                margin-right: 7px;
              }

              a {

                img,
                svg {
                  height: 17px;
                  object-fit: scale-down;
                  width: 15px;
                }
              }
            }
          }
        }
      }

      tr:first-child td:first-child {
        border-top-left-radius: 10px;
      }

      tr:first-child td:last-child {
        border-top-right-radius: 10px;
      }

      tr:last-child td:first-child {
        border-bottom-left-radius: 10px;
      }

      tr:last-child td:last-child {
        border-bottom-right-radius: 10px;
      }
    }
  }
}

.card {
  .card-header {
    border-color: $gainsboro;
    background-color: $white;

    .form-group {
      margin-bottom: 0 !important;
    }

    ul {
      &.list-inline {
        margin-bottom: 0;

        li {
          &:not(:last-child) {
            margin-right: 15px;
          }

          img,
          svg {
            height: 17rem;
            object-fit: scale-down;
            width: 15rem;
          }
        }
      }
    }
  }

  .card-body {
    &.table-responsive {
      padding: 0;
    }
  }

  .card-footer {
    border-top: 0;
    background-color: $white;
  }
}

.modal {
  .modal-dialog {
    .modal-content {
      .modal-header {
        background-color: #54a5d529;
      }

      .modal-footer {
        background-color: #54a5d529;

        .btn {
          margin: 0;
          padding: 7px 25px 8px;
        }
      }
    }
  }
}

.form-switch {
  padding-left: 1.5em;

  .form-check-label {
    padding-top: 3px;
  }

  .form-check-input {
    width: 40px;
    height: 20px;
    margin-left: -51px;

    &:checked {
      background-color: $success;
      border-color: $success;
    }

    &:focus {
      box-shadow: 0 0 0 0.25rem rgba(43, 127, 117, 0.25);
    }
  }
}

.eye-button {
  background-color: $secondary;
  width: 33px;
  height: 33px;
  display: inline-block;
  text-align: center;
  // border-radius: 10px;
  color: $white;
  align-items: center;
  display: flex;
  justify-content: center;

  &:hover {
    color: $white;
  }
}

.eye-edit {
  background-color: $primary;
  width: 33px;
  height: 33px;
  display: inline-block;
  text-align: center;
  border-radius: 10px;
  color: $white;
  padding: 0px;
  align-items: center;
  display: flex;
  justify-content: center;

  &:hover {
    color: $white;
    background-color: $primary;
  }
}

.eye-delete {
  background-color: $danger;
  width: 33px;
  height: 33px;
  display: inline-block;
  text-align: center;
  border-radius: 10px;
  color: $white;
  padding: 0px;
  align-items: center;
  display: flex;
  justify-content: center;

  &:hover {
    color: $white;
    background-color: $danger;
  }
}

.btn-success {
  padding: 8px 25px;
  background: $success;
  color: $white;
  line-height: 25.2px;
  font-size: 15px;
  font-weight: 400;
  border-radius: 5px;
  transition: all 0.15s ease;
  border-color: $success;

  &:hover {
    background: #2b6c65;
    color: $white;
    border-color: #2b6c65;
  }
}

.btn-outline-success {
  padding: 8px 25px;
  color: $success;
  font-size: 15px;
  font-weight: 400;
  border-radius: 5px;
  transition: all 0.15s ease;
  border: 1px solid $success;

  &:hover {
    color: $white;
    border-color: #2b6c65;
    background: #2b6c65;
  }
}

.btn.btn-primary {
  padding: 8px 25px;
  background: $primary;
  color: $white;
  line-height: 25.2px;
  font-size: 15px;
  font-weight: 400;
  border-radius: 5px;
  transition: all 0.15s ease;
  border-color: $primary;

  &:hover {
    background: $primary;
    color: $white;
    border-color: $primary;
  }
}

.btn-danger {
  padding: 8px 25px;
  color: $white;
  font-size: 15px;
  font-weight: 400;
  border-radius: 5px;
  transition: all 0.15s ease;
  border: 1px solid $danger;

  &:hover {
    color: $white;
    border-color: $danger;
    background: $danger;
  }
}

.btn-outline-danger {
  padding: 8px 25px;
  color: $danger;
  font-size: 15px;
  font-weight: 400;
  border-radius: 5px;
  transition: all 0.15s ease;
  border: 1px solid $danger;
  background-color: $white;

  &:hover {
    color: $white;
    border-color: $danger;
    background: $danger;
  }
}

.booking-table-placehoder {
  .placeholder-col {
    width: 120px;
  }

  ul {
    li {
      height: 30px;
      width: 32px;
      border-radius: 3px;
    }
  }
}

.invalid-feedback-text-success {
  color: green;
  background: #91e783b5;
  width: fit-content;
  border-radius: 26px;
  padding: 4px 10px 4px 10px;
  font-weight: 600;
  font-size: 10px;
}

.invalid-feedback-text-danger {
  color: red;
  background: rgb(255 133 143 / 71%);
  width: fit-content;
  border-radius: 26px;
  padding: 4px 10px 4px 10px;
  font-weight: 600;
  font-size: 10px;
}

.date-field {
  min-width: 130px;
}

.chat-view-modal {
  .modal-body {
    height: 300px;
    overflow-y: auto;
  }

  .chat-message {
    small {
      font-size: 12px;
    }
  }
}

.chat-modal {
  position: fixed;
  bottom: 0;
  right: 0;
  margin: 0;
  width: 300px;

  .modal-header {
    p {
      font-weight: 600;
    }
  }

  /* Custom small width */
  .chat-box-row {
    .input-group {
      .form-control {
        &:focus {
          box-shadow: none;
          // border: 0px;
        }
      }
    }

    .btn-primary {
      width: 60px;
    }

    .spinner-border {
      height: 20px;
      width: 20px;
      --bs-spinner-border-width: 0.15em;
    }

    form {
      width: 100%;
      justify-content: start;
    }
  }
}

.chat-window {
  display: flex;
  flex-direction: column;
}

.status-select-field {
  width: max-content;
}

.phone-number-field {
  .react-tel-input {
    .country-list {

      .search-box {
        width: 88%;

      }
    }

    .search {
      padding: 0px;
      border: 1px solid #dee2e6;
      margin: 9px;
      border-radius: 3px;

      .search-emoji {
        padding-left: 5px;
      }

      .search-box {
        border: 0px;
      }
    }

  }
}
@import "../../../../assets/scss/theme-color.scss";

.gems-section {
	.card {
		padding: 0px;

		.card-header {
			padding: 8px 18px;
		}
	}
}
@import "../../../../assets/scss/theme-color.scss";

.gems-section {
	.card {
		padding: 0px;

		.card-header {
			padding: 8px 18px;

			h6 {
				margin-bottom: 0px;
			}
		}

		.card-body {
			padding: 0px;

			.dropdown-bookign {
				width: 88px !important;
			}
		}


	}
}

.details-form {
	.card {
		padding: 20px;

		h6 {
			margin-bottom: 20px;
		}
	}
}

.avatar-upload {
	position: relative;
	margin-top: -18px;
	// max-width: 205px;

	.add-more-images {
		height: 120px !important;
	}

	input {
		display: none;

		+label {

			&::before {
				content: "";
				position: absolute;
				top: 0px;
				left: 0;
				right: 0;
				text-align: center;
				margin: auto;
				height: 100%;
				width: 100%;
				z-index: 999999;
				cursor: pointer;

			}
		}
	}

	.avatar-preview {
		width: 240px;
		height: 210px;
		position: relative;

		.image-preview {
			width: 100%;
			height: 100%;
			background-size: contain;
			background-repeat: no-repeat;
			background-position: center;
			border-radius: 10px;
			// background-image: url(../../dashboard/assets/icons/unnamed.jpg);
			border: 2px dashed $primary;
			overflow: hidden;
			background-color: $white;

			a {
				display: block;

				img {
					width: 100%;
					z-index: 3;
					position: relative;
					object-fit: cover;
				}
			}

			p {
				color: $primary;
				justify-content: center;
				display: flex;
				align-items: center;
				position: absolute;
				left: 0;
				right: 0;
				bottom: 0;
				top: 0;
				z-index: 0;

				img {
					transform: rotate(180deg);
				}

			}
		}


	}
}

.edit-images {
	display: inline-block;
	width: 34px;
	height: 34px;
	border-radius: 100%;
	background: $white;
	border: 1px solid #d6d6d6;
	box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
	cursor: pointer;
	font-weight: normal;
	position: absolute;
	right: 0;
	top: 0;
	z-index: 3;


	&:after {
		content: "";
		background-image: url(../../dashboard/assets/icons/edit-icon.svg);
		position: absolute;
		top: 0px;
		left: 0;
		right: 0;
		bottom: 0;
		text-align: center;
		margin: auto;
		height: 13px;
		background-repeat: no-repeat;
		width: 13px;
		background-position: center;
		background-size: contain;
	}
}

.gemstone-details-modal {
	.modal-body {
		h6 {
			margin-bottom: 5px;
		}
	}
}

.remove-list-style {
	ul {
		padding: 0;
		margin-bottom: 0px;

		li {
			list-style: none;
		}
	}
}
$light: #f2360a24;
$primary: #54a5d5;
$white: #fff;
$antiflashwhite: #E6E9EB;
$warning: #DBAA00;
$whitesmoke: #F9FAFB;
$danger: #F2360A;
$mintcream: #f0f9f3;
$mustardyellow: #dfb31c;
$coolmint: #dcf3ff;
$dark: #223322;
$gainsboro: #dee2e6;
$secondary: #6C7278;
$eerieblack: #1b1f2326;
$blackrgb: #0000001A;
$taupegray: #958f8f;
$nickel: #6C7278;
$border-radius: 6px;
$black : #000;
$darkblue: #797979;
$silver: #79797980;
@import "../../../../assets/scss/theme-color.scss";

.details-edit-section {
	.flex-shrink-0 {
		img {
			object-fit: cover;
			border-radius: 83px;

		}

	}

	.customer-name-list {
		.card {
			padding: 20px;

			ul {
				margin-bottom: 0px;

				li {
					margin-left: 20px;
					padding-right: 30px;
					border-right: 2px solid #dddd;

					&:first-child {
						margin-left: 0px;
					}

					&:last-child {
						border: 0px;
						padding-right: 0px;
					}

					p {
						margin-bottom: 0px;
					}

					h6 {
						margin-bottom: 5px;
					}
				}
			}
		}
	}

}

.inquiry-section {
	.card {
		padding: 0px;

		.card-header {
			padding: 15px 20px;

			.btn-primary {
				padding: 5px 10px;
			}
		}

		.card-body {
			padding: 0px;

			// .enquiry-id {
			// 	width: 170px;
			// }
		}
	}
}

.comments-card-sticky {
	top: 67px !important;

	.comments-box {
		padding: 20px;

		h6 {
			text-transform: capitalize;
		}

		p {
			margin-top: 4px;
			font-weight: 500;
			text-transform: capitalize;

		}
	}
}


.comments-box-row {
	padding: 20px;

	h5 {
		margin-bottom: 20px;
		text-transform: capitalize;
	}

	.comments-box-col {
		p {
			text-transform: capitalize;
		}
	}
}

.card-footer-comment {
	position: relative;

	.reply-button {
		.btn {
			padding: 0px;
			border: 0px;
			position: absolute;
			top: 19px;
			right: 20px;
			margin: auto;
		}
	}
}

.comments-box-row.border-bottom {
	max-height: 441px;
	overflow-y: scroll;
}
@import "../../../../assets/scss/theme-color.scss";

.daily-section {
	margin-top: 40px;

	.card {

		.card-header {

			// border: 0px;
			padding: 8px 20px;

			h6 {
				margin-bottom: 0px;
			}
		}

		ul {
			li {
				margin-bottom: 15px;
			}
		}
	}
}

.text {
	.avatar-upload {

		.avatar-preview {
			width: 245px;
		}
	}
}

.setting-section {
	position: relative;

	.close-images {
		position: relative;
		display: block;

		.close-icon-top {
			width: 26px;
			height: 26px;
			border-radius: 100%;
			background: #fff;
			border: 1px solid #d6d6d6;
			box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
			cursor: pointer;
			font-weight: normal;
			position: absolute;
			right: 0;
			top: 0;
			text-align: center;
			align-items: 3;
			align-items: center;
			display: flex;
			justify-content: center;
		}

		.user-upload-images {
			width: 100%;
			min-height: 120px;
			object-fit: cover;
		}
	}

	.setting-images-group {
		.row {
			display: grid;
			grid-template-columns: repeat(auto-fit, minmax(50%, 1fr));

			.col {
				// margin-bottom: 10px;
			}
		}

		.close-images {
			img {
				width: 100%;
			}
		}



		.add-images {
			position: relative;

			.add-icon-top {
				position: absolute;
				left: 0;
				bottom: 0;
				right: 0;
				margin: auto;
				top: 0;
			}
		}

	}

	.avatar-upload {
		margin-top: -4px;
		line-height: 0px;

		.avatar-preview {
			width: 100%;

			p {
				margin-bottom: 0px;
			}
		}
	}
}
@import "../../../../assets/scss/theme-color.scss";

.team-section {
	.card-header {
		padding: 8px 18px;
	}

	.card {
		padding: 0px;

		.clinet-name {
			width: 200px;
		}
	}

	table {
		tbody {
			tr {
				td {


					ul {
						li {
							@media only screen and (max-width: 1440px) {
								margin-bottom: 5px;
								margin-right: 3px !important;
							}

							// @media only screen and (max-width: 1400px) {
							// 	margin-bottom: 5px;
							// 	margin-right: 0px !important;
							// }
						}
					}
				}
			}
		}
	}
}